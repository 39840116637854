<template>
	<el-form class="page" :model="korisnik" :rules="rules" ref="form" :label-position="'top'">
		<el-row>
			<el-col>
				<el-form-item size="large">
					<el-tooltip class="item" effect="dark" :content="$t('global.nazad')" placement="top">
						<el-button @click="$utils('goBack')" type="info" icon="fas fa-hand-point-left" circle></el-button>
					</el-tooltip>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6">
				<el-form-item :label="$t('korisnici.korisnik')" prop="username">
					<el-input v-model="korisnik.username" :maxLength="150"/>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6">
				<el-form-item :label="$t('korisnici.sifra')" prop="password">
					<el-input v-model="korisnik.password" :minLength="4" :maxLength="80" :placeholder="$t('korisnici.promenaSifre')" type="password"/>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6">
				<el-form-item :label="$t('korisnici.pristup')" prop="id_grupe_user">
					<select-grupe-korisnika class="full" v-model="korisnik.id_grupe_user" ref="grupe-korisnika"/>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6">
				<el-form-item :label="$t('korisnici.email')">
					<el-input v-model="korisnik.email" :maxLength="180"/>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6">
				<el-form-item :label="$t('korisnici.status')">
					<el-switch
							v-model="korisnik.active"
							active-color="#13ce66"
							inactive-color="#ff4949"
							:active-text="$t('global.aktivan')"
							:inactive-text="$t('global.neaktivan')"
							:active-value="1"
							:inactive-value="0">
					</el-switch>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6">
				<el-form-item :label="$t('korisnici.telefon')">
					<el-input v-model="korisnik.phone"/>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6">
				<el-form-item :label="$t('korisnici.ime')">
					<el-input v-model="korisnik.first_name"/>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6">
				<el-form-item :label="$t('korisnici.prezime')">
					<el-input v-model="korisnik.last_name"/>
				</el-form-item>
			</el-col>
		</el-row>
		<el-row>
			<el-col>
				<el-form-item size="large">
					<el-button :disabled="onSubmitLoading" v-loading="onSubmitLoading" type="success" @click="onSubmit">{{ $t('global.sacuvaj') }}</el-button>
					<el-button type="info" @click="$utils('goBack')">{{ $t('global.odustani') }}</el-button>
				</el-form-item>
			</el-col>
		</el-row>
	</el-form>
</template>

<script>
export default {
	name: 'korisnik-edit',
	data() {
		return {
			fullscreenLoading: true,
			onSubmitLoading: false,
			id: '',
			formCreate: '',
			korisnik: {
				username: '',
				password: '',
				id_grupe_user: '',
				email: '',
				active: 1,
				phone: '',
				first_name: '',
				last_name: ''
			},
			rules: {
				id_grupe_user: [{ required: true, trigger: 'blur', message: this.$t('message.obavezno') }],
				username: [{ required: true, max: 150, trigger: 'blur', message: this.$t('message.obavezno') }],
				password: [{ required: true, max: 80, min: 4, trigger: 'blur', message: this.$t('message.obavezno') }],
				email: [{ required: true, max: 180, trigger: 'blur', message: this.$t('message.obavezno') }]
			},
			continentList: ['eu']
		};
	},
	mounted() {
		this.id = this.$route.params.id;
		this.formCreate = this.id === 'create';

		this.$store.dispatch('setHeader', {
			title: this.$t('korisnici.korisnici')
		});

		// Obavezno polje kada je dodavanje
		if (this.id > 0 && this.id !== 'create')
			this.rules.password[0].required = false;

		this.$utils('stopLoadingAfter', [this.getDrzave()]);
	},
	methods: {
		getDrzave() {
			if (!this.formCreate) {
				return this.$get('korisnici', { id: this.id }).then(data => {
					this.korisnik = data;
				});
			}
		},
		onSubmit() {
			this.onSubmitLoading = true;
			return this.$refs.form.validate().then(() => {
				let data = JSON.stringify(this.korisnik);

				if (this.formCreate)
					this.$save('korisnici', data);
				else
					this.$update('korisnici', { id: this.id }, data);
			}).catch((err) => {
				this.onSubmitLoading = false;
				return err;
			});
		}
	},
	components: {
		selectGrupeKorisnika: require('../../components/select/grupeKorisnika').default
	}
};
</script>
