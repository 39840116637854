<template>
    <el-select :value="value"
               v-loading="loading"
               @change="emit"
               clearable
               @clear="emit(null)"
               filterable
               :disabled="disabled"
               :placeholder="$t('select.selectGrupeKorisnika')">
        <el-option v-for="val in grupeKorisnika"
                   :key="val.id"
                   :value="val.id"
                   :label="val.naziv">
        </el-option>
    </el-select>
</template>

<script>
export default {
	name: 'select-grupe-korisnika',
	props: ['value', 'disabled'],
	data() {
		return {
			grupeKorisnika: [],
			loading: true
		};
	},
	mounted() {
		this.$get('grupeKorisnika', { limit: 2000 }).then(data => {
			this.grupeKorisnika = data.content;
			this.$nextTick(() => {
				this.loading = false;
			});
		});
	},
	methods: {
		emit(id) {
			this.$emit('input', id);
		}
	}
};
</script>

